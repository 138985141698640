<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">关键字</div>
					<div class="inputs_box"><input type="text" placeholder="请输入名称" v-model="forms.keyword"></div>
				</div>
				
				<div class="search_flex">
					<div class="texts">劳保用品</div>
					<div class="inputs_box">
						<el-select filterable="" clearable v-model="forms.laborId" placeholder="请选择" style="width:236px;">
							<el-option v-for="item in lb_list" :key="item.title" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				

				<div class="search_flex">
					<div class="texts">时间范围</div>
					<div class="inputs_box">
						<el-date-picker :picker-options="pickerOptions" clearable v-model="date_range" format="yyyy-MM-dd"
							value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</div>
				</div>

				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
				</div>
			</div>
			<div class="bom_box_list">

				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="100"></el-table-column> -->
					<el-table-column prop="name" label="姓名" width="210"></el-table-column>
					<el-table-column prop="user.level6" label="发放工种" width="250"></el-table-column>
					<el-table-column prop="title" label="领取劳保用品" width="270"></el-table-column>
					<el-table-column prop="xf_at" label="应领取时间"  ></el-table-column>
					<el-table-column label="领取状态" width="250">
						<template slot-scope="scope">
							<div class="lingqu-box">
								<span v-if="scope.row.status == 0">未领取</span>
								<span v-if="scope.row.status == 1" style="color: #3BBF7D;">已领取</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="rec_date" label="领取时间" width="250"></el-table-column>
					
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		 

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的



	export default {
		components: {
			page,

		},
		data() {
			return {
				dialogVisible: false,
				 
			 

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					position: '',
					name: '',
					start: '',
					end: '',
					laborId:'',//劳保id
					status: '', //未领取 2已领取
				},
				date_range: [],

				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围

				lingquList: [{
					value: 0,
					label: '未领取'
				}, {
					value: 1,
					label: '已领取'
				}],
				form_top: {
					lingqu: 0, //领取状态
				},
				lb_list:[],
				
				
				pickerOptions: {
					disabledDate(time) {
						console.log('time', time)
						return (time.getMonth() + 1) > new Date().getMonth();
					}
				},
				
			};
		},

		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("receiveLabor", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					userId:this.$route.query.userId||''
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list
					 
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},
			get_lb_list(){
				console.log(this.forms)
				this.loading = true
				this.$api("laborList", {
					// mobile:this.mobile
					page: 1,
					limit: 1000000,
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						 this.lb_list=res.data.list
						 if(this.$route.query.labor_id){
							this.forms.laborId=Number(this.$route.query.labor_id)
						}
					} else {
						alertErr(res.msg)
					}
				});
			},

		},
		computed: {},
		watch: {
			date_range(value) {
				//this.$log('日期范围', value)

				this.forms.start = value[0] || ''
				this.forms.end = value[1] || ''
			}
		},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
			this.get_lb_list()
			
		},
		mounted() {

		}


	};
</script>

<style scoped lang="less">
	/deep/.el-dialog {
		border-radius: 8px;
	}

	.content-info {
		padding: 0px 110px;

		.form-item {
			display: flex;
			align-items: center;
			margin-bottom: 14px;

			.values {
				flex: 1;
			}

			.chuangjian {
				width: 101px;
				height: 38px;
				background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
				border-radius: 4px 4px 4px 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 16px;
				margin-left: 14px;
				cursor: pointer;

				&:hover {
					opacity: 0.78;
				}
			}



			.select {
				flex: 1;
				display: flex;

				/deep/.el-select {
					flex: 1;
				}
			}
		}

		.title {
			font-size: 16px;
			display: flex;
			width: 120px;
			margin-right: 5px;

			&.requird::before {
				content: '*';
				display: block;
				color: red;
				margin-right: 5px;
			}
		}

	}

	.zhanghao-flex {

		display: flex;

		align-items: center;

	

		span {

			font-size: 14px;

			color: #3697FD;

			margin: 0 14px;

			cursor: pointer;

	

			&:last-child {

				

			}

		}

	}

	/deep/.el-table__cell:nth-child(1) {
		.cell {
			display: flex;
			justify-content: center;
		}
	}

	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;
					margin-bottom: 18px;

					.texts {
						width: 65px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						margin-left: 12px;
						border-radius: 4px;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>